import { createApp } from 'vue'
import Popup from './Popup'
function PopupInstance(msg, isClose) {
  console.log(' :>> ', msg);
  return new Promise((resolve, reject) => {
    // 实例化组件，createApp第二个参数是props
    const popupInstance = createApp(Popup, {
      msg,
      isClose,
      isHover: true,
      doComfirm: () => {

        unmount()
        resolve()
      },
      doCancel: () => {

        unmount()
        reject(new Error())
      }
    })
    // 卸载组件
    const unmount = () => {
      popupInstance.unmount()
      document.body.removeChild(parentNode)
    }
    // 创建一个挂载容器
    const parentNode = document.createElement('div')
    document.body.appendChild(parentNode)
    // 挂载组件
    popupInstance.mount(parentNode)
  })
}

export default PopupInstance