<template>
  <div class="endLive-container"></div>
</template>

<script>
import PopopInstance from "@/components/unit/PopupInstance";
import weixin from "@/common/weixin";
export default {
  name: "endLive",
  mounted() {
    document.title = "";
    let content = {
      title: "温馨提示",
      html: `<div class="popup-content-main">直播已结束，感谢观看</div>`,
      btngroup: { sure: "确认" },
      align: "center",
    };
    PopopInstance(content)
      .then(() => {
        weixin.wx.closeWindow();
      })
      .catch(() => {
        weixin.wx.closeWindow();
      });
  },
};
</script>

<style >
</style>